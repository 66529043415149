<template>
  <div id="container">
    <div id="contact-title" v-if="!skipTitle">CONTACT</div>
    <div id="contact-data">
      <div>
        <b>Merch Support: </b>support
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        goodmerchonly.com
      </div>
      <div>
        <b>Band: </b>contact
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        goodkidofficial.com
      </div>
      <div>
        <b>Send Us Letters: </b>
        <code
          >Jacob Tsafatinos PO Box 65039 TORONTO RPO CHESTER, ON, M4K 3Z2</code
        >
      </div>
      <div>
        <b>Management: </b> contact
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        goodpeopleonly.com
      </div>
      <div>
        <b>Booking (USA): </b> tmany
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        teamwass.com, lchenfeld
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        teamwass.com
      </div>
      <div>
        <b>Booking (Canada): </b> himmelfarb
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        feldman-agency.com
      </div>
      <div>
        <b>Booking (UK/EU): </b> tom.taaffe<font-awesome-icon
          :icon="['fas', 'at']"
          class="at-icon"
        />teamwass.com
      </div>
      <div>
        <b>Press (USA): </b> jim
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        bighassle.com
      </div>
      <div>
        <b>Press (Canada): </b> cristina
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        listenharder.com
      </div>
      <div>
        <b>Press (UK): </b> sibohan
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        publiccitypr.com
      </div>
      <div>
        <b>Radio (USA): </b> david
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        in2unemusic.com
      </div>
      <div>
        <b>Radio (Canada): </b> dave.lombardi
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        canvasmedia.ca
      </div>
      <div>
        <b>Radio (UK): </b> hayley
        <font-awesome-icon :icon="['fas', 'at']" class="at-icon" />
        publiccitypr.com
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAt } from "@fortawesome/free-solid-svg-icons";
library.add(faAt);

export default {
  props: {
    skipTitle: Boolean,
  },
};
</script>

<style scoped>
#contact-title {
  padding-top: 40px;
  font-family: "blok" !important;
  color: white;
  text-shadow: 1px 2px #2b3d2e;
  font-size: 80px;
  margin-bottom: 35px;
}
#contact-data {
  color: #3a3939;
}

.at-icon {
  height: 11px;
}
</style>
